<template>
    <div>
        <img src="@/assets/logo.png" alt="Logo" />
    </div>
</template>

<script>
    
</script>

<style>
    img {
        display: block;
        margin: 0 auto;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
</style>
