        <template>
            <div>
                <h1>Ads</h1>
                <p>Here are the ads we are going to create:</p>
                <ul>
                    <li style="color: green;">olx Hugo 1
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/aluguer-de-autocaravana-casa-mvel-IDIq3qc.html" target="_blank">IDIq3qc</a></li>
                            <li>Titulo: Aluguer de autocaravana - Casa Móvel</li>
                            <li>Serviços->Eventos->Aluguer de Equipamentos</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Benfica, Lisboa</li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Hugo 2
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/aluguer-de-autocaravana-familiar-IDHSd7J.html" target="_blank">IDHSd7J</a></li>
                            <li>Titulo: Aluguer de autocaravana familiar</li>
                            <li>Serviços->Eventos->Aluguer de Veículos</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Charneca de Caparica e Sobreda, Setúbal</li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Hugo 3
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/autocaravana-fugaemfamilia-IDIx5ct.html" target="_blank">IDIX5ct</a></li>
                            <li>Titulo: Autocaravana FugaEmFamilia</li>
                            <li>Serviços->Eventos->Aluguer de Veículos</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Fernão Ferro, Setúbal</li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Hugo 4
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/aluguer-de-autocaravana-para-ferias-IDIwB5D.html" target="_blank">IDIwB5D</a></li>
                            <li>Titulo: Autocaravana aluguer para férias</li>
                            <li>Serviços->Eventos->Aluguer de Veículos</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Almada, Cova Da Piedade, Pragal E Cacilhas, Setúbal</li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Hugo 5
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/campervan-alugo-autocaravana-IDIxo2B.html" target="_blank">IDIxo2B</a></li>
                            <li>Titulo: campervan alugo autocaravana </li>
                            <li>Serviços->Eventos->Aluguer de Veículos</li>
                            <li>Preço: 80€/dia</li>
                            <li>Localização: Montijo E Afonsoeiro, Setúbal </li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Ana 1
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/autocaravana-alugo-vendo-IDIx5ol.html" target="_blank">IDIx5ol</a></li>
                            <li>Titulo: Autocaranava alugo/vendo</li>
                            <li>Carros, motos e barcos->Autocaravanas - Reboques->Fiat</li>
                            <li>Preço: 33550€</li>
                            <li>Localização: Barreiro E Lavradio, Setúbal</li>
                        </ul>
                    </li>
                    <li style="color: green;">olx Ana 2
                        <ul>
                            <li>link: <a href="https://www.olx.pt/d/anuncio/autocaravana-para-frias-IDIwB59.html" target="_blank">IDIwB59</a></li>
                            <li>Titulo: Autocaravana para férias</li>
                            <li>Serviços->Eventos->Aluguer de Veículos</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Mafra, Lisboa</li>
                        </ul>
                    </li>
                    <li>olx ana paula 1</li>
                    <li>olx ana paula 2</li>
                    <li>olx Inês 1</li>
                    <li>olx Inês 2</li>
                    <li>olx Braga 1</li>
                    <li>olx Braga 2</li>
                    <li style="color: green;">custojusto ana 1
                        <ul>
                            <li>link: <a href="https://www.custojusto.pt/setubal/veiculos/autocaravanas-reboques/capucino/autocaravana-para-ferias-43118009" target="_blank">https://www.custojusto.pt/setubal/veiculos/autocaravanas-reboques/capucino/autocaravana-para-ferias-43118009</a></li>
                            <li>Titulo: Autocaravana para férias</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Chahrneca de Caparica, Almada</li>
                        </ul>
                    </li>
                    <li>custojusto ana 2</li>
                    <li style="color: green;">Yescapa hugo 1
                        <ul>
                            <li>link: <a href="https://www.yescapa.pt/campers/67929" target="_blank">https://www.yescapa.pt/campers/67929</a></li>
                            <li>Titulo: Autocaravana capucino de Hugo</li>
                            <li>Preço: 90€/dia</li>
                            <li>Localização: Costa da Caparica, Almada</li>
                            <li>Reservas de ultima hora: O dia de partida </li> 
                            <li><a href="https://www.yescapa.pt/d/campers/67929/calendar" target="_blank">Calendario</a></li>
                        </ul>
                    </li>
                    <li>campanda</li>
                    <li>custojusto</li>
                    <li>idealista</li>
                    <li>imovirtual</li>
                    <li>sapo</li>
                    <li>google</li>
                    <li>facebook</li>
                    <li>instagram</li>
                    <li>airbnb hugo 1 
                        <ul>
                            <li>link: <a href="https://www.airbnb.co.uk/rooms/1220140618137670635" target="_blank">https://www.airbnb.co.uk/rooms/1220140618137670635</a></li>
                            <li>Titulo: FugaEmFamilia- Family Motorhome</li>
                            <li>Preço: 118£/dia</li>
                            <li>Localização: Costa da Caparica, Portugal</li>
                        </ul>
                    </li>
                    <li>booking.com</li>
                    <li>tripadvisor</li>
                    <li>trivago</li>
                    <li>expedia</li>
                    <li>skyscanner</li>
                    <li>kayak</li>
                    <li>momondo</li>
                    <li>rentalcars</li>
                    <li>autoeurope</li>
                    <li>europcar</li>
                    <li>hertz</li>
                </ul>
                <!-- button to check if every ad is created -->
                <button>Check if every ad is created</button>
            </div>
        </template>

<script>
export default {

}
</script>

<style></style>